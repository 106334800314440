<template>
  <div
    v-click-outside="closeDropdown"
    class="searchable-select"
    @keydown.up="toUp"
    @keydown.down="toDown"
  >
    <input
      v-model="search"
      :id="id"
      type="text"
      ref="searchInput"
      autocomplete="off"
      @focus="openDropdown"
      @input="onInputSearch"
      @keydown.enter.prevent
      @keydown.up.prevent
      @keydown.down.prevent
    />

    <div v-if="!isDropdown" class="arrow__searchable">
      <ArrowSearch />
    </div>

    <div v-if="isDropdown" class="dropdown">
      <VSearchableDropdown
        ref="dropdown"
        :autoFocus="false"
        :disabledSearch="true"
        :options="searchableOptions"
        :selected="model"
        :search="search"
        @select="selectOption"
        @changeSearch="search = $event"
      />
    </div>
  </div>
</template>

<script>
import VSearchableDropdown from '@/components/ui/VSearchableDropdown';
import ArrowSearch from '@/assets/icons/arrow_search-select.svg';

export default {
  name: 'SearchableSelect',
  components: {
    VSearchableDropdown,
    ArrowSearch,
  },

  props: {
    id: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    model: String,
    desc: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
    searchKeys: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
      search: '',
      isDropdown: false,
      focusedOption: 0,
      filtered: false,
  }),
  computed: {
    searchableOptions() {
      if (!this.options) {
        return false;
      }

      if (!this.search || !this.filtered) {
        return this.options;
      }

      const $self = this;
      const searchQuery = this.search.toLowerCase();

      return this.options.filter(option =>
        $self.searchKeys.some(key =>
          String(option[key]).toLowerCase().includes(searchQuery)
        )
      )
    },
  },
  watch: {
    model() {
      this.updateSearch();
    },
  },

  created() {
    this.updateSearch();
  },

  methods: {
    selectOption(value) {
      this.$emit('change', value);
      this.updateSearch();
      this.filtered = false;
      this.closeDropdown();
    },
    onInputSearch() {
      this.$emit('change', this.search);
      this.filtered = true;

      if (!this.isDropdown) {
        this.openDropdown();
      }
    },
    val(option) {
      return this.value
        ? option[this.value]
        : option.value;
    },
    updateSearch() {
      this.search = this.model;
    },

    openDropdown() {
      this.isDropdown = true;
    },
    closeDropdown() {
      this.isDropdown = false;
    },

    toUp() {
      this.$refs.dropdown.up();
    },
    toDown() {
      this.$refs.dropdown.down();
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-theme {
  .searchable-select {
    .dropdown {
      background: $darkThemeBgTwo;
      box-shadow: 0 6px 13px 0 rgba(25, 25, 25, 0.48);
    }
  }
}

.searchable-select {
  position: relative;
  .arrow__searchable {
    position: absolute;
    top: 9px;
    right: 15px;
  }
  .dropdown {
    position: absolute;
    top: 100%;
    background: #fff;
    border-radius: 4px;
    left: 0;
    margin-top: 8px;
    overflow: hidden;
    box-shadow: 0 3px 8px 0 rgba(158, 158, 158, 0.55);
    z-index: 5;
    width: 100%;
    outline: none;
    max-height: 285px;
    overflow-y: auto;
  }
}
</style>
