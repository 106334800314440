<template>
  <div
    class="input__dropdown-search"
    @keydown.up.prevent.stop="up"
    @keydown.down.prevent.stop="down"
  >
    <div v-show="!disabledSearch" class="input__search">
      <IconMagnific />
      <input
        v-model="localSearch"
        type="text"
        :placeholder="$t('search')"
        ref="input"
        tabindex="-1"
        @focus="$emit('focus')"
      />
    </div>
    <div v-if="options" v-bar class="input__results">
      <div class="input__results-wrapper">
        <div v-if="options.length" class="input__results-content">
          <button
            v-for="(item, index) of options"
            :key="index"
            :class="[
              'input__result',
              {
                input__result_focused: target === index,
                input__result_selected: selectedOption(item)
              }
            ]"
            :ref="`result${index}`"
            :data-result-input-key="index + 1"
            @click.stop.prevent="select(item)"
            @keyup.enter.prevent
          >
            <div
              v-if="useDots && item.color"
              :style="{ background: item.color }"
              class="input__result-color-dot"
            ></div>

            <div
              v-else-if="item.img || item.logo"
              class="input__result-img"
            >
              <img :src="item.img || item.logoLink || item.logo" />
            </div>

            <div
              v-else-if="item.icon"
              class="input__result-icon"
            >
              <VIcon :name="item.icon" :color="item.iconColor || 'blue'" />
            </div>

            <div
              v-else-if="colorLabel && item.color"
              :style="{ background: item.color }"
              class="input__result-color"
            ></div>

            <div
              v-else-if="item.color && !colorLabel"
              :style="{ background: item.color }"
              class="input__result-word"
            >
              {{ nameToLetter(item.name || item.email) }}
            </div>

            <div class="input__result-name">
              {{ $t(item.name || item.email) }}
              <span v-if="item.name && item.email">({{ item.email }})</span>
              <span v-else-if="item.name && item.description">{{ item.description }}</span>
            </div>
          </button>
        </div>
        <div v-else class="input__results-empty">{{ $t('No results found') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import IconMagnific from '@/assets/icons/magnific2.svg';
import VIcon from '@/components/ui/VIcon';

export default {
  name: 'VSearchableDropdown',
  components: {
    IconMagnific,
    VIcon,
  },

  props: {
    disabledSearch: {
      type: Boolean,
      default: false,
    },

    colorLabel: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      default: null,
    },

    search: {
      type: String,
      default: '',
    },

    autoFocus: {
      type: Boolean,
      default: true,
    },

    useDots: {
      type: Boolean,
      default: false,
    },

    selected: null,
  },
  data: () => ({
    localSearch: '',
    target: null,
  }),
  watch: {
    disabledSearch() {
      this.focusInput();
    },

    localSearch() {
      this.$emit('changeSearch', this.localSearch);
    },

    target(val) {
      if (val === null) {
        this.focusInput();
      } else if (this.$refs[`result${val}`]) {
        this.$refs[`result${val}`][0].focus();
      }
    },
  },

  methods: {
    selectedOption(item) {
      return this.selected
        ? this.selected === item.id || this.selected === item.value || this.selected === item.email
        : false;
    },

    focusInput() {
      if (!this.disabledSearch) {
        this.$refs.input.focus();
      } else {
        this.setTarget(0);
      }
    },

    nameToLetter(name) {
      return name.slice(0, 1);
    },

    select(item) {
      this.$emit('select', item);
    },

    setTarget(index) {
      this.target = index;
    },

    down() {
      if (this.target < this.options.length - 1 && this.target !== null) {
        this.setTarget(this.target + 1);
      } else {
        this.setTarget(0);
      }
    },

    up() {
      if (this.target) {
        this.setTarget(this.target - 1);
      } else {
        this.setTarget(this.options.length - 1);
      }
    },
  },

  beforeDestroy() {
    this.localSearch = '';
    this.$emit('beforeDestroy');
  },
  updated() {
    if (this.target === null && this.autoFocus) {
      this.focusInput();
    }
  },
  mounted() {
    if (this.autoFocus) {
      this.focusInput();
    }
  },

  i18n: {
    messages: {
      'en': {
        'No results found': 'No results found',
        'Active projects': 'Active projects',
        'Archival projects': 'Archival projects',
      },
      'ru': {
        'No results found': 'Результаты не найдены',
        'Active projects': 'Активные проекты',
        'Archival projects': 'Архивные проекты',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.dark-theme {
  .input {
    $root: &;

    &__search {
      background-color: $darkThemeBgOne;
      border-color: $darkThemeBorder;

      input {
        color: $darkThemeTextLight;
      }

      svg {
        path {
          &:nth-child(2) {
            fill: $darkThemeIconGrey;
          }

          &:nth-child(1) {
            fill: $darkThemeIconLightGrey;
          }
        }
      }
    }

    &__result {
      color: $darkThemeTextColor;

      &-name {
        span {
          color: darken($darkThemeText, 10%);
        }
      }

      &:focus {
        background: $darkThemeBgOne;

        #{$root} {
          &-name {
            color: $darkThemeTextLight !important;

            span {
              color: $darkThemeText
            }
          }
        }
      }

      &:hover {
        background: $darkThemeBgOne;

        .input__result-name {
          color: #fff !important;

          span {
            color: $darkThemeText
          }
        }
      }

      &_selected {
        background: $darkThemeBgThree !important;

        .input__result-name {
          color: #fff;
        }

        #{$root} {
          &-img {
            border-color: transparent;
          }

          &-icon {
            svg {
              path {
                fill: $darkThemeText;
              }
            }
          }
        }
      }

      &-icon {
        svg {
          path {
            &:nth-child(2) {
              fill: $darkThemeBlue;
            }

            &:nth-child(1) {
              fill: $darkThemeLightBlue;
            }
          }
        }
      }

      &-img {
        border: 1px solid $darkThemeBorder;
      }

      &-color {
        &-dot {
          box-shadow: 0 0 4px 0 rgba($darkThemeBorder, .6);
        }
      }
    }
  }
}

.input {
  &__search {
    width: 100%;
    position: relative;
    border-bottom: 1px solid $borderGrey;

    svg {
      display: block;
      position: absolute;
      left: 14px;
      width: 18px;
      height: 19px;
      top: 50%;
      margin-top: -10.5px;
      pointer-events: none;

      path {
        &:nth-child(1) {
          fill: $lightGrey;
        }

        &:nth-child(2) {
          fill: $grey;
        }
      }
    }

    input {
      display: block;
      outline: none;
      width: 100%;
      height: 38px;
      font-size: rem(12px);
      border: none;
      font-weight: 300;
      padding: 0 11px 0 45px;
      background: transparent;
      box-shadow: none !important;
    }

    &_focused {
      svg {
        path {
          &:nth-child(1) {
            fill: $lightBlue;
          }

          &:nth-child(2) {
            fill: $blue;
          }
        }
      }

      input {
        background: #fff;
      }
    }
  }

  &__results {
    border-radius: 0 0 4px 4px;
    overflow: hidden;

    &-content {
      max-height: 200px;
    }

    &-empty {
      padding: 11px;
      color: $darkGrey;
      font-weight: 300;
      text-align: center;
      font-size: rem(13px);
    }
  }

  &__result {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 9px;
    border: none;
    outline: none;
    width: 100%;
    min-height: 36px;
    $root: &;

    &:focus {
      background: $lightThemeBackground2;
    }

    &-word {
      text-transform: uppercase;
      color: rgba(#000, .6);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 11px;
      font-weight: 700;
      border: 1px solid $lightGrey;
      flex: none;
      margin-right: 10px;
    }

    &-color {
      text-transform: uppercase;
      color: rgba(#000, .6);
      border-radius: 50%;
      width: 14px;
      height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 11px;
      font-weight: 700;
      flex: none;
      margin-right: 10px;

      &-dot {
        width: 12px;
        height: 12px;
        border-radius: 4px;
        flex: none;
        margin-right: 10px;
        box-shadow: 0 0 4px 0 rgba(#fff, .6)
      }
    }

    &-icon {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: none;
      margin-right: 10px;

      svg {
        display: block;
        width: 20px;
        height: 18px;
      }
    }

    &-img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid $lightGrey;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: none;
      margin-right: 10px;

      img {
        display: block;
        width: 100%;
      }
    }

    &-name {
      font-size: rem(11px);
      text-align: left;

      span {
        flex: none;
        font-size: rem(10px);
        color: $darkGrey;
        display: block;
      }
    }

    &:hover {
      background: $lightThemeBackground2;

      #{$root} {
        &-img {
          border-color: transparent;
        }
      }
    }

    &_selected {
      background: #DDDDE7 !important;

      #{$root} {
        &-img {
          border-color: transparent;
        }

        &-name {
          color: #000;
        }

        &-icon {
          svg {
            path {
              fill: $blue;
            }
          }
        }
      }
    }
  }
}
</style>
